<template>
    <div class="main_container">
        <div class="title_container">
            <div>每日老师布置作业次数图：</div>
            <div class="right_container">
                <!--                <el-date-picker-->
                <!--                        size="small"-->
                <!--                        v-model="value1"-->
                <!--                        type="month"-->
                <!--                        placeholder="选择月份"-->
                <!--                        style="margin-right: 20px">-->
                <!--                </el-date-picker>-->
                <div class="user_count">当前老师布置作业次数:{{userCount}}</div>
            </div>
        </div>
        <ve-line :data="chartData"></ve-line>

        <div class="title_container">
            <div>老师布置作业时间段统计：</div>
        </div>
        <ve-histogram :data="chartData4" :settings="chartSettings4"></ve-histogram>

        <div class="title_container">布置作业身份统计：</div>
        <ve-histogram :data="chartData2" :settings="chartSettings"></ve-histogram>
    </div>
</template>


<script>
    import {getDateFromTimeStamp2} from "@/utils/dateUtil";

    export default {
        name: "user",

        data() {
            return {
                chartSettings: {
                    stack: {'用户': ['老师', '学生']}
                },
                chartSettings4: {
                    metrics: ['时间段占比'],
                    dimension: ['日期']
                },

                userCount: 0,

                // value1: '',//选择日期




                radio: '1'

            }
        },
        mounted() {
            this.getDate11();
            this.getDate12();
            this.getDate13();
        },
        methods: {
            getDate11() {
                this.$store.dispatch("data/getData11", {});
            },
            getDate12() {
                this.$store.dispatch("data/getData12", {});
            },
            getDate13() {
                this.$store.dispatch("data/getData13", {});
            },
        },
        computed: {
            // 每日老师布置作业次数图
            chartData() {
                let rows = this.$store.state.data.data11.map((item) => {
                    let row = {};
                    row['日期'] = getDateFromTimeStamp2(item.createTime);
                    row['每日老师布置作业次数'] = item.count;
                    return row;
                })
                if (this.userCount === 0 && rows.length > 0) {
                    this.userCount = rows[rows.length - 1]['每日老师布置作业次数'];
                }
                let columns = ['日期', '每日老师布置作业次数'];
                return {rows, columns}
            },
            // 每日老师布置作业次数图
            chartData4() {
                let rows = this.$store.state.data.data12.map((item) => {
                    let row = {};
                    row['日期'] = item.time+"-"+(item.time+1);
                    row['时间段占比'] = item.homeworkCount;
                    return row;
                })
                let columns = ['日期', '时间段占比'];
                return {rows, columns}
            },
            // 布置作业身份统计
            chartData2() {
                let rows = this.$store.state.data.data13.map((item,index) => {
                    let row = {};
                    let total =item.teacherCount+item.studentCount
                    row['日期'] = 'w' + (index + 1);
                    row['老师'] = item.teacherCount*100/total;
                    row['学生'] = item.studentCount*100/total;
                    return row;
                })
                let columns = ['日期', '老师','学生'];
                return {rows, columns}
                // // 布置作业身份统计
                // chartData2: {
                //     columns: ['日期', '老师', '学生',],
                //         rows: [
                //         {'日期': 'w1', '老师': 1393, '学生': 1093},
                //         {'日期': 'w2', '老师': 3530, '学生': 3230},
                //         {'日期': 'w3', '老师': 2923, '学生': 2623},
                //         {'日期': 'w4', '老师': 1723, '学生': 1423},
                //         {'日期': 'w5', '老师': 3792, '学生': 3492},
                //         {'日期': 'w6', '老师': 4593, '学生': 4293},
                //         {'日期': 'w7', '老师': 4593, '学生': 4293},
                //         {'日期': 'w8', '老师': 4593, '学生': 4293},
                //     ]
                // },
            },

        }
    }
</script>

<style scoped>
    .main_container {
        padding: 10px 20px 20px 20px;
    }

    .title_container {
        font-weight: bolder;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .right_container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user_count {
        font-size: 18px;
        margin-left: 30px;
    }

</style>
